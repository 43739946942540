<template>
	<section class="devices-container">
		<NoteModal
			v-if="isNoteModalOpen && !isMobile"
			@saveDevice="saveDevice"
			@closeModal="closeModal"
			:modalTexts="modalTexts"
			:currDevice="currDevice"
		/>
		<ErrorsModal
			v-if="isErrorsModalOpen && !isMobile"
			@saveDevice="saveDevice"
			@closeModal="closeModal"
			:modalTexts="modalTexts"
			:currDevice="currDevice"
		/>
		<Tabs :isRTL="isRTL">
			<Tab :label="$t('status')" :to="'/admin/devices'" exact>
				<DeviceStatusList
					:devicesStatus="devicesStatus"
					v-if="devicesStatus && !isLoading"
					@cellClick="cellClick"
				/>
				<Loading v-if="!devicesStatus || !devicesStatus.length || isLoading" class="loader" style="height:75vh;" />
			</Tab>
			<Tab :label="$t('connectivity')" :to="'/admin/devices/connectivity'">
				<DeviceConnectivity
					:devicesConnectivity="devicesConnectivity"
					v-if="!isDevicesConnectivityEmpty && !isConnectivityLoading"
					@updateFilterTime="updateFilterTime"
				/>
				<Loading v-if="isConnectivityLoading" class="loader" style="height:75vh;" />
				<div v-else-if="isDevicesConnectivityEmpty">
					{{ $t('no_data_available') }}
				</div>
			</Tab>
			<Tab :label="$t('power')" :to="'/admin/devices/power'">
				<DeviceBatteryPower
					:devicesBatteryPower="devicesBatteryPower"
					v-if="!isDevicesBatteryPowerEmpty && !isPowerLoading"
				/>
				<Loading v-if="isPowerLoading" class="loader" style="height:75vh;" />
				<div v-else-if="isDevicesBatteryPowerEmpty">
					{{ $t('no_data_available') }}
				</div>
			</Tab>
		</Tabs>
	</section>
</template>

<script>
import DeviceStatusList from '../components/DeviceStatusList'
import DeviceConnectivity from '../components/DeviceConnectivity'
import NoteModal from '../components/NoteModal'
import ErrorsModal from '../components/ErrorsModal'
import DeviceBatteryPower from '../components/DeviceBatteryPower'
import { mapGetters } from 'vuex'
import Loading from '@/modules/common/components/Loading'
import Tabs from '@/modules/common/components/Tabs/Tabs'
import Tab from '@/modules/common/components/Tabs/Tab'
import sensorService from '../../control-panel/services/sensorService'
import swalService from "@/modules/common/services/swalService"
import io from 'socket.io-client/dist/socket.io'
const SOCKET_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3000'

export default {
	data() {
		return {
			isLoading: false,
			isConnectivityLoading: false,
			modalTexts: null,
			currDevice: null,
			isPowerLoading: false
		}
	},
	created() {
		if (this.socket) this.socket.disconnect()
		this.socket = io.connect(SOCKET_URL, {})
		const { site: siteId } = this.filterSelected
		this.socket.emit('device-list', { siteId: siteId })
		this.socket.on('updateDeviceList', () => this.$store.dispatch({ type: "getDevices", siteId }))
	},
	methods: {
		async updateSiteInfo() {
			this.isLoading = true
			const { site: siteId } = this.filterSelected
			this.$store.commit({ type: 'resetDevicesConnectivity' })
			this.$store.commit({ type: 'resetDevicesBatteryPower' })
			await Promise.all([this.$store.dispatch({ type: 'getDevices', siteId })])
			this.isLoading = false
		},
		async updateConnectivity() {
			this.isConnectivityLoading = true
			const { site: siteId, time: dates } = this.filterSelected
			await this.$store.dispatch({ type: 'getDevicesConnectivity', siteId, dates })
			this.isConnectivityLoading = false
		},
		async updateBatteryPower() {
			this.isPowerLoading = true
			await this.$store.dispatch({ type: 'getDevicesBatteryPower', filter: this.filterSelected })
			this.isPowerLoading = false
		},
		updateFilterTime(filterTime) {
			this.$store.commit({
				type: 'updateFilter',
				field: 'timeType',
				value: 'custom_time'
			})
			this.$store.commit({
				type: 'updateFilter',
				field: 'time',
				value: filterTime
			})
		},
		async cellClick(device, type) {
			this.currDevice = device
			if (type === 'note') this.addNoteModalInfo()
			else if (type === 'errors') this.addErrorsModalInfo()
		},
		addNoteModalInfo() {
			const { room_name } = this.currDevice
			this.modalTexts = {
				headerPrimary: 'Note',
				headerSecondary: room_name,
				footerConfirm: 'Add'
			}
		},
		addErrorsModalInfo() {
			const { room_name } = this.currDevice
			this.modalTexts = {
				headerPrimary: `${this.$t('latest_errors')}`,
				headerSecondary: room_name,
			}
		},
		async saveDevice() {
			delete this.currDevice.room_name
			try {
				await sensorService.updateSensor(this.currDevice)
				const { site: siteId } = this.filterSelected
				await this.$store.dispatch({ type: "getDevices", siteId })
				swalService.savedMsg(this.$t('Your work has been saved'))
			} catch (err) {
				swalService.errorMsg();
			}
			this.closeModal()
		},
		closeModal() {
			this.currDevice = null
			this.modalTexts = null
		}
	},
	computed: {
		...mapGetters(['isRTL', 'devicesStatus', 'devicesConnectivity', 'devicesBatteryPower', 'filterSelected']),
		isDevicesConnectivityEmpty() {
			return Object.keys(this.devicesConnectivity).length === 0
		},
		isNoteModalOpen() {
			if (!this.currDevice) return false
			const { note, latest_errors } = this.currDevice
			return note && !latest_errors && this.modalTexts
		},
		isErrorsModalOpen() {
			if (!this.currDevice) return false
			const { latest_errors } = this.currDevice
			return latest_errors && this.modalTexts
		},
		isMobile() {
			if (screen.width <= 820) {
				return true
			} else {
				return false
			}
		},
		isDevicesBatteryPowerEmpty() {
			return Object.keys(this.devicesBatteryPower).length === 0
		}
	},
	watch: {
		'filterSelected.site': {
			async handler() {
				await this.updateSiteInfo()
			},
			immediate: true
		},
		'filterSelected.time': {
			async handler() {
				if (!this.isDevicesConnectivityEmpty && !this.isDevicesBatteryPowerEmpty) {
					await Promise.all([this.updateConnectivity(), this.updateBatteryPower()])
				} else if (!this.isDevicesConnectivityEmpty) {
					await this.updateConnectivity()
				} else if (!this.isDevicesBatteryPowerEmpty) {
					await this.updateBatteryPower()
				}
			}
		},
		'$route.path': {
			async handler() {
				if (this.$route.path.includes('connectivity') && this.isDevicesConnectivityEmpty) {
					await this.updateConnectivity()
				}
				if (this.$route.path.includes('power') && this.isDevicesBatteryPowerEmpty) {
					await this.updateBatteryPower()
				}
			},
			immediate: true
		}
	},
	destroyed() {
		if (this.socket) this.socket.disconnect()
	},
	components: {
		DeviceStatusList,
		DeviceConnectivity,
		DeviceBatteryPower,
		Loading,
		Tabs,
		Tab,
		NoteModal,
		ErrorsModal
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.top-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
</style>
